import Vue from 'vue'
import VueRouter from 'vue-router'

// import Vuex from '../store/index'
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/m',
    name: 'm',
    component: () => import('../views/m.vue')
  },
  {
    path: '/m2',
    name: 'm2',
    component: () => import('../views/m2.vue')
  },
  {
    path: '/mpad',
    name: 'mpad',
    component: () => import('../views/mpad.vue')
  },
];
const router = new VueRouter({
  mode: 'history',
  routes: routes
});
export default router;