import Vue from 'vue'
import App from './App.vue'
import router from './router'
import hybrid from '@/helper/hybrid.js';

Vue.config.productionTip = false;
import waterfall from 'vue-waterfall2'
// 客户端调用
Vue.prototype.$hybrid = hybrid;
Vue.use(waterfall);

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');
// export default { components:{vueWaterfallEasy}}
