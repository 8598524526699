/**
 * Created by bikuAdmin on 2018/8/10.
 */

var hybrid = {};

if (typeof window !== 'undefined') {
  window.Hybrid = hybrid;
}

export default hybrid;
