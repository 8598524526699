<template>
  <div class="main">

    <div class="header" :style="headerStyle">
      <div class="logo"><img src="../assets/img/m/logo@2x.png"></div>
      <div class="video">
        <video class="right-img" src="https://www.7lemon.cc/upload_file/web/home/abroad_home.mp4" :autoplay="true" :loop="true" :muted="true" poster></video>
        <div class="phone-img"></div>
      </div>
      <div class="txt">Poster & Photo Editor</div>
      <div class="download">
        <a href="https://apps.apple.com/app/id1584730935"><img src="../assets/img/m/apply_1@2x.png"></a>
        <a href="https://play.google.com/store/apps/details?id=com.biku.design" style="margin-left: 11px;"><img src="../assets/img/m/play_1@2x.png"></a>
      </div>
    </div>

    <div class="content">
      <div class="item">
        <img src="../assets/img/m/imgtxt/1@2x.png">
        <div class="title">POSTER MAKER</div>
        <div class="split"></div>
        <div class="txt1">Free custom poster</div>
        <div class="txt1">templates design</div>
        <div class="txt2">Everything from Instagram Story, Instagram Post, Facebook Post, Poster, Flyer, Invitation, Photo Collage and much more can be designed. Go from idea to
          finish in a few minutes!
        </div>
      </div>
      <div class="item">
        <img src="../assets/img/m/imgtxt/2@2x.png">
        <div class="title">PHOTO EDITOR</div>
        <div class="split"></div>
        <div class="txt1">Free photo & image</div>
        <div class="txt1">editing tools</div>
        <div class="txt2">Text Art, Resize, Remove Background, Photo Grids, Magic Eraser, Format Change, Add Watermark, Add Frames, Blur Photo and other powerful features for you.
          It's the easiest photo editor you'll ever use!
        </div>
      </div>
      <div class="item">
        <img src="../assets/img/m/imgtxt/3@2x.png">
        <div class="title">TEXT ART</div>
        <div class="split"></div>
        <div class="txt1">Stylish text font creator and</div>
        <div class="txt1">text editor</div>
        <div class="txt2">200+ fonts, 60+ font styles and 150+ font combinations for you! You can edit the opacity, colour, bold, underline, italic, vertical, spacing, curve and
          more of the text.
        </div>
      </div>
      <div class="item">
        <img src="../assets/img/m/imgtxt/4@2x.png">
        <div class="title">PHOTO COLLAGE</div>
        <div class="split"></div>
        <div class="txt1">Many grid layouts and</div>
        <div class="txt1">pic collage</div>
        <div class="txt2">You can choose from grid layouts and collage templates with different numbers of photos and different canvas sizes. And change the position of photos,
          change the background, add texts and add stickers.
        </div>
      </div>
      <div class="item">
        <img src="../assets/img/m/imgtxt/5@2x.png">
        <div class="title">IMAGE CUTOUT</div>
        <div class="split"></div>
        <div class="txt1">Remove and change</div>
        <div class="txt1">background</div>
        <div class="txt2">Automatically remove background in seconds! Get transparent images or add different colours, textures and styles of backgrounds.</div>
      </div>
      <div class="item">
        <img src="../assets/img/m/imgtxt/6@2x.png">
        <div class="title">MAGIC ERASER</div>
        <div class="split"></div>
        <div class="txt1">Easily remove objects</div>
        <div class="txt1">from photo</div>
        <div class="txt2">Very easy to remove anything you want in seconds and without erasing the background! Ability to guess and draw missing part of the photo.</div>
      </div>
      <div class="item">
        <img src="../assets/img/m/imgtxt/7@2x.png">
        <div class="title">ADD WATERMARK</div>
        <div class="split"></div>
        <div class="txt1">Create watermark,</div>
        <div class="txt1">copyright your photos</div>
        <div class="txt2">200+ fonts, 80+ watermark styles for you! You can add info about your brand, email, social media and more to the watermark. And change a single watermark
          to full screen with one click.
        </div>
      </div>
      <div class="item">
        <img src="../assets/img/m/imgtxt/8@2x.png">
        <div class="title">IMAGE RESIZER</div>
        <div class="split"></div>
        <div class="txt1">Easily resize images</div>
        <div class="txt1">for free</div>
        <div class="txt2">Resize or customise photos for every occasion without losing quality. And you can change the size unit to px or mm and keep the original aspect ratio.
        </div>
      </div>

      <div class="bottom-info">
        <div class="txt1">Start designing with</div>
        <div class="txt1">7Lemon</div>
        <div class="txt2">Let us give you the tools and the place to create to make your artistic visions a reality!</div>

        <div class="download">
          <a href="https://apps.apple.com/app/id1584730935"><img src="../assets/img/m/apply_2@2x.png"></a>
          <a href="https://play.google.com/store/apps/details?id=com.biku.design" style="margin-left: 11px;"><img src="../assets/img/m/play_2@2x.png"></a>
        </div>
      </div>

    </div>

    <div class="footer" :style="footerStyle">
      <div class="content">
        <div class="item">
          <div class="title">ABOUT US</div>
          <div class="txt">qnsj001@gmail.com</div>
          <div class="txt"><a href="https://c1ns.cn/7Lemon_user_agreement_en">Terms Of Use</a></div>
          <div class="txt"><a href="https://c1ns.cn/bk_private_en">Privacy Policy</a></div>
        </div>
        <div class="item">
          <div class="title">FOLLOW US</div>
          <div class="txt"><a href="https://www.instagram.com/photolab_postermaker/">Instagram</a></div>
          <div class="txt"><a href="https://www.facebook.com/photolab8">Facebook</a></div>
          <div class="txt"><a href="https://www.tiktok.com/@photolab_postermaker">Tik Tok</a></div>
        </div>
      </div>
      <div class="copyright">Copyright © Biku Network Technology Co., Ltd.</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Index",
    data() {
      return {
        headerStyle: '',
        footerStyle: '',
      }
    },
    created() {
      if (this.isMobile()) {
        console.log('移动端');
      }
      else {
        document.location.href = "/";
      }
    },
    mounted() {
      this.calHeaderFooterHeight();

      window.onresize = () => {
        return (() => {
          this.$nextTick(() => {
            if (!this.isMobile()) {
              document.location.href = "/";
            }
            this.calHeaderFooterHeight();
          });
        })();
      };
    },
    computed: {},
    methods: {
      calHeaderFooterHeight() {
        let screenWidth = window.screen.width;
        // console.log('screenWidth', screenWidth);
        let headerHeight = 2 * screenWidth;
        this.headerStyle = "height: " + headerHeight + "px;";

        let footerHeight = 892 / 750 * screenWidth;
        this.footerStyle = "height: " + footerHeight + "px;";

        if (screenWidth < 768) {
          document.location.href = "/m2";
        }
        if (screenWidth <= 280) {
          document.location.href = "/m";
        }
      },

      isMobile() {
        let isMobile = false;
        if ((navigator.userAgent.match(/(x11|phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
          // console.log('移动端');
          isMobile = true;
        }
        if (document.body.clientWidth < 800) {
          isMobile = true;
        }
        return isMobile;
      },
    }
  }
</script>

<style scoped lang="less">

  .header {
    background-image: url("../assets/img/m/bg.png");
    background-size: cover;
    width: 100%;
  }

  .header .logo {
    margin: 0 auto;
    text-align: center;
    padding-top: 54px;
  }

  .header .logo img {
    width: 282px;
  }

  .header .video {
    margin: 0 auto;
    position: relative;
    margin-top: 50px;
    width: 529px;
    height: 1102px;
    text-align: center;
  }

  .header .video .right-img {
    width: 485px;
    height: 1053px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: calc(25px * var(--scale, 1));
    position: absolute;
    top: 24px;
    left: 21.9px;
  }

  .header .video .phone-img {
    width: 529px;
    height: 1102px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../assets/img/m/phone@2x.png");
    background-size: cover;
  }

  .header .txt {
    text-align: center;
    margin-top: 72px;

    font-size: 56.85px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 67px;
  }

  .header .download {
    display: flex;
    justify-content: center;
    margin-top: 65.6px;
  }

  .header .download a img {
    width: 268.96px;
    height: 87.5px;
  }

  @media (min-width: 768px) and (max-width: 768px) {
    .header .video {
      width: 495.616px;
      height: 1032.192px;
    }

    .header .video .right-img {
      width: 454.656px;
      height: 987.136px;
      top: 22.528px;
      left: 20.48px;
    }

    .header .video .phone-img {
      width: 495.616px;
      height: 1032.192px;
    }

    .header .txt {
      margin-top: 67px;
      font-size: 53px;
      line-height: 63px;
    }

    .header .download {
      margin-top: 61px;
    }

    .header .download a img {
      width: 252px;
      height: 82px;
    }
  }


  .content {
    margin-top: 118px;
  }

  .content .item {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 159.6px;
  }

  .content .item img {
    width: 610px;
    height: 406px;
  }

  .content .item .title {
    font-size: 30.6px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #22C673;
    line-height: 54px;

    margin-top: 21px;
  }

  .content .item .split {
    width: 96px;
    height: 4px;
    background: #22C673;
    margin: 0 auto;
    text-align: center;

    margin-top: 15.3px;
    margin-bottom: 24px;
  }

  .content .item .txt1 {
    font-size: 41.5px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #020000;
    line-height: 54px;

    text-align: center;
    margin: 0 auto;
  }

  .content .item .txt2 {
    width: 656px;
    font-size: 26px;
    font-family: ArialMT;
    color: #666666;
    line-height: 41px;

    text-align: center;
    margin: 0 auto;
    margin-top: 15px;
  }

  .content .bottom-info {
    text-align: center;
  }

  .content .bottom-info .txt1 {
    font-size: 41.5px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #020000;
    line-height: 54px;

    text-align: center;
    margin: 0 auto;
  }

  .content .bottom-info .txt2 {
    width: 656px;
    font-size: 26px;
    font-family: ArialMT;
    color: #666666;
    line-height: 41px;

    text-align: center;
    margin: 0 auto;
    margin-top: 15px;
  }

  .content .bottom-info .download {
    display: flex;
    justify-content: center;
    margin-top: 21px;
  }

  .content .bottom-info .download a img {
    width: 268.96px;
    height: 87.47px;
  }

  .footer {
    background-image: url("../assets/img/m/footer.png");
    background-size: cover;
    width: 100%;
    margin-top: 136px;
  }

  .footer .content {
    padding-left: 83px;
    padding-top: 131px;
  }

  .footer .content .item {
    margin-bottom: 74px;
  }

  .footer .content .item .title {
    font-size: 30px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 26px;

    text-align: left;
    margin-bottom: 19px;
  }

  .footer .content .item .txt, .footer .content .item .txt a {
    text-align: left;
    text-decoration: none;

    font-size: 24px;
    font-family: ArialMT;
    color: #FFFFFF;
    line-height: 63px;
  }

  .footer .copyright {
    font-size: 26px;
    font-family: AlibabaPuHuiTiR;
    color: #FFFFFF;
    line-height: 26px;

    text-align: center;
    margin-top: 35px;
  }
</style>
