<template>
  <div class="main">
    <div class="header">
      <div class="info">
        <div class="base">
          <div class="logo">
            <img src="../assets/img/LOGO@2x.png" alt="PhotoLab" srcset="../assets/img/LOGO@2x.png 2x">
          </div>
          <div class="txt1">Poster & Photo Editor</div>
          <div class="txt2">Has everything you need to create amazing designs for every occasion.</div>
          <div class="download">
            <a href="https://apps.apple.com/app/id1584730935" target="_blank"><span class="apple"></span></a>
            <a href="https://play.google.com/store/apps/details?id=com.biku.design" target="_blank"><span class="play"></span></a>
          </div>
        </div>
        <div class="video">
          <video class="right-img" src="https://www.7lemon.cc/upload_file/web/home/abroad_home.mp4" :autoplay="true" :loop="true" :muted="true" poster></video>
          <div class="phone-img"></div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="container">

        <div class="imgtxt">
          <div class="img"><img src="../assets/img/imgtxt/1@2x.jpg" alt="" srcset="../assets/img/imgtxt/1@2x.jpg 2x"></div>
          <div class="txt" style="margin-left: 91px;">
            <div class="title">POSTER MAKER</div>
            <div class="line"></div>
            <div class="desc1">Free custom poster templates design</div>
            <div class="desc2">Everything from Instagram Story, Instagram Post, Facebook Post, Poster, Flyer, Invitation, Photo Collage and much more can be designed. Go from idea
              to finish in a few minutes!
            </div>
          </div>
        </div>

        <div class="imgtxt">
          <div class="txt">
            <div class="title">PHOTO EDITOR</div>
            <div class="line"></div>
            <div class="desc1">Free photo & image editing tools</div>
            <div class="desc2">Text Art, Resize, Remove Background, Photo Grids, Magic Eraser, Format Change, Add Watermark, Add Frames, Blur Photo and other powerful features for
              you. It's the easiest photo editor you'll ever use!
            </div>
          </div>
          <div class="img" style="margin-left: 91px;"><img src="../assets/img/imgtxt/2@2x.jpg" alt="" srcset="../assets/img/imgtxt/2@2x.jpg 2x"></div>
        </div>

        <div class="imgtxt">
          <div class="img"><img src="../assets/img/imgtxt/3@2x.jpg" alt="" srcset="../assets/img/imgtxt/3@2x.jpg 2x"></div>
          <div class="txt" style="margin-left: 91px;">
            <div class="title">TEXT ART</div>
            <div class="line"></div>
            <div class="desc1">Stylish text font creator and text editor</div>
            <div class="desc2">200+ fonts, 60+ font styles and 150+ font combinations for you! You can edit the opacity, colour, bold, underline, italic, vertical, spacing, curve
              and more of the text.
            </div>
          </div>
        </div>

        <div class="imgtxt">
          <div class="txt">
            <div class="title">PHOTO COLLAGE</div>
            <div class="line"></div>
            <div class="desc1">Many grid layouts and pic collage</div>
            <div class="desc2">You can choose from grid layouts and collage templates with different numbers of photos and different canvas sizes. And change the position of
              photos, change the background, add texts and add stickers.
            </div>
          </div>
          <div class="img" style="margin-left: 91px;"><img src="../assets/img/imgtxt/4@2x.jpg" alt="" srcset="../assets/img/imgtxt/4@2x.jpg 2x"></div>
        </div>

        <div class="imgtxt">
          <div class="img"><img src="../assets/img/imgtxt/5@2x.jpg" alt="" srcset="../assets/img/imgtxt/5@2x.jpg 2x"></div>
          <div class="txt" style="margin-left: 91px;">
            <div class="title">IMAGE CUTOUT</div>
            <div class="line"></div>
            <div class="desc1">Remove and change background</div>
            <div class="desc2">Automatically remove background in seconds! Get transparent images or add different colours, textures and styles of backgrounds.</div>
          </div>
        </div>

        <div class="imgtxt">
          <div class="txt">
            <div class="title">MAGIC ERASER</div>
            <div class="line"></div>
            <div class="desc1">Easily remove objects from photo</div>
            <div class="desc2">Very easy to remove anything you want in seconds and without erasing the background! Ability to guess and draw missing part of the photo.</div>
          </div>
          <div class="img" style="margin-left: 91px;"><img src="../assets/img/imgtxt/6@2x.jpg" alt="" srcset="../assets/img/imgtxt/6@2x.jpg 2x"></div>
        </div>

        <div class="imgtxt">
          <div class="img"><img src="../assets/img/imgtxt/7@2x.jpg" alt="" srcset="../assets/img/imgtxt/7@2x.jpg 2x"></div>
          <div class="txt" style="margin-left: 91px;">
            <div class="title">ADD WATERMARK</div>
            <div class="line"></div>
            <div class="desc1">Create watermark, copyright your photos</div>
            <div class="desc2">200+ fonts, 80+ watermark styles for you! You can add info about your brand, email, social media and more to the watermark. And change a single
              watermark to full screen with one click.
            </div>
          </div>
        </div>

        <div class="imgtxt">
          <div class="txt">
            <div class="title">IMAGE RESIZER</div>
            <div class="line"></div>
            <div class="desc1">Easily resize images for free</div>
            <div class="desc2">Resize or customise photos for every occasion without losing quality. And you can change the size unit to px or mm and keep the original aspect
              ratio.
            </div>
          </div>
          <div class="img" style="margin-left: 91px;"><img src="../assets/img/imgtxt/8@2x.jpg" alt="" srcset="../assets/img/imgtxt/8@2x.jpg 2x"></div>
        </div>

        <div class="bottomtxt">
          <div class="btxt1">Start designing with 7Lemon</div>
          <div class="btxt2">Let us give you the tools and the place to create to make your artistic</div>
          <div class="btxt2">visions a reality!</div>
          <div class="download">
            <a href="https://apps.apple.com/app/id1584730935" target="_blank"><span class="apple"></span></a>
            <a href="https://play.google.com/store/apps/details?id=com.biku.design" target="_blank"><span class="play"></span></a>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="info">
        <div class="links">
          <div class="link1">
            <div class="title">ABOUT US</div>
            <span class="contact">qnsj001@gmail.com</span>
            <a href="https://c1ns.cn/7Lemon_user_agreement_en" target="_blank">Terms Of Use</a>
            <a href="https://c1ns.cn/bk_private_en" target="_blank">Privacy Policy</a>
          </div>
          <div class="link2">
            <div class="title">FOLLOW US</div>
            <a href="https://www.instagram.com/photolab_postermaker/" target="_blank">Instagram</a>
            <a href="https://www.facebook.com/photolab8" target="_blank">Facebook</a>
            <a href="https://www.tiktok.com/@photolab_postermaker" target="_blank">Tik Tok</a>
          </div>
        </div>
        <div class="copyright">Copyright © Biku Network Technology Co., Ltd.</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Index",
    data() {
      return {}
    },
    created() {
      if (this.isMobile()) {
        document.location.href = "/m2";
      }
    },
    mounted() {
      window.onresize = () => {
        return (() => {
          this.$nextTick(() => {
            if (this.isMobile()) {
              document.location.href = "/m2";
            }
          });
        })();
      };
    },
    computed: {},
    methods: {
      isMobile() {
        let isMobile = false;
        if ((navigator.userAgent.match(/(x11|phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
          // console.log('移动端');
          isMobile = true;
        }
        if (document.body.clientWidth < 800) {
          isMobile = true;
        }
        return isMobile;
      },
    }
  }
</script>

<style scoped lang="less">
  .header {
    width: 100%;
    height: 627px;
    background: #22C673;
  }

  .header .info {
    margin: 0 auto;
    width: 1425px;
    max-width: 1425px;
    height: 627px;
    display: flex;
    justify-content: space-between;
    background-image: url("../assets/img/header_bg.png");
    background-size: cover;
  }

  .header .info .base {
    padding-top: 108px;
    padding-left: 226px;
  }

  .header .info .base .txt1 {
    font-size: 55px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 64px;

    margin-top: 72px;
  }

  .header .info .base .txt2 {
    width: 531px;
    height: 66px;
    font-size: 23px;
    font-family: ArialMT;
    color: #FFFFFF;
    line-height: 33px;
    letter-spacing: 1px;

    margin-top: 18px;
  }

  .header .info .logo {
    display: flex;
    align-items: center;
  }

  .header .info .logo img {
    display: block;
    width: 287px;
  }

  .header .info .base .download {
    display: flex;
    justify-content: flex-start;
    margin-top: 38px;
  }

  .header .info .base .download a {
    margin-right: 17px;
  }

  .header .info .base .download a .apple {
    display: block;
    width: 187px;
    height: 61px;
    background-image: url("../assets/img/apply_1@2x.png");
    background-size: cover;
  }

  .header .info .base .download a .apple:hover {
    background-image: url("../assets/img/apply_1_Press@2x.png");
    background-size: cover;
  }

  .header .info .base .download a .play {
    display: block;
    width: 187px;
    height: 61px;
    background-image: url("../assets/img/play_1@2x.png");
    background-size: cover;
  }

  .header .info .base .download a .play:hover {
    background-image: url("../assets/img/play_1_Press@2x.png");
    background-size: cover;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min--moz-device-pixel-ratio: 2) {
    .header .info .base .download a .apple {
      background-image: url("../assets/img/apply_1@2x.png");
    }

    .header .info .base .download a .apple:hover {
      background-image: url("../assets/img/apply_1_Press@2x.png");
    }

    .header .info .base .download a .play {
      background-image: url("../assets/img/play_1@2x.png");
    }

    .header .info .base .download a .play:hover {
      background-image: url("../assets/img/play_1_Press@2x.png");
    }
  }

  .header .info .video {
    margin-top: 46px;
    border-radius: 39px;
    position: relative;
    width: 257px;
    height: 535px;
    margin-right: 249px;
  }

  .header .info .video .right-img {
    width: 237px;
    height: 511px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: calc(25px * var(--scale, 1));
    position: absolute;
    top: 12px;
    left: 10px;
  }

  .header .info .video .phone-img {
    width: 257px;
    height: 535px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../assets/img/phone.png");
    background-size: cover;
  }

  .content {
    width: 100%;
  }

  .content .container {
    width: 1425px;
    max-width: 1425px;
    margin: 0 auto;
    margin-top: 95px;
  }

  .content .container .imgtxt {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 143px;
    margin-bottom: 125px;
  }

  .content .container .imgtxt .img img {
    display: block;
    width: 531px;
    height: 354px;
  }

  .content .container .imgtxt .txt {
    width: 531px;
    text-align: left;
  }

  .content .container .imgtxt .txt .title {
    font-size: 26px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #22C673;
    line-height: 45px;
    letter-spacing: 1px;
  }

  .content .container .imgtxt .txt .line {
    width: 81px;
    height: 4px;
    background: #22C673;
    margin-top: 14px;
  }

  .content .container .imgtxt .txt .desc1 {
    font-size: 35px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #020000;
    line-height: 45px;

    margin-top: 19px;
  }

  .content .container .imgtxt .txt .desc2 {
    font-size: 22px;
    font-family: ArialMT;
    color: #666666;
    line-height: 34px;

    margin-top: 13px;
  }

  .content .container .bottomtxt {
    text-align: center;
    margin-top: 67px;
  }

  .content .container .bottomtxt .btxt1 {
    font-size: 35px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #020000;
    line-height: 45px;

    margin-bottom: 24px;
  }

  .content .container .bottomtxt .btxt2 {
    font-size: 24px;
    font-family: ArialMT;
    color: #666666;
    line-height: 34px;
  }

  .content .container .bottomtxt .download {
    display: flex;
    justify-content: center;
    margin-top: 53px;
  }

  .content .container .bottomtxt .download a .apple {
    display: block;
    width: 204px;
    height: 68px;
    background-image: url("../assets/img/apply_2@2x.png");
    background-size: cover;
    margin-right: 17px;
  }

  .content .container .bottomtxt .download a .apple:hover {
    background-image: url("../assets/img/apply_2_Press@2x.png");
    background-size: cover;
  }

  .content .container .bottomtxt .download a .play {
    display: block;
    width: 204px;
    height: 68px;
    background-image: url("../assets/img/play_2@2x.png");
    background-size: cover;
  }

  .content .container .bottomtxt .download a .play:hover {
    background-image: url("../assets/img/play_2_Press@2x.png");
    background-size: cover;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min--moz-device-pixel-ratio: 2) {
    .content .container .bottomtxt .download a .apple {
      background-image: url("../assets/img/apply_2@2x.png");
    }

    .content .container .bottomtxt .download a .apple:hover {
      background-image: url("../assets/img/apply_2_Press@2x.png");
    }

    .content .container .bottomtxt .download a .play {
      background-image: url("../assets/img/play_2@2x.png");
    }

    .content .container .bottomtxt .download a .play:hover {
      background-image: url("../assets/img/play_2_Press@2x.png");
    }
  }

  .footer {
    width: 100%;
    height: 381px;
    background: #22C673;
    margin-top: 92px;
  }

  .footer .info {
    margin: 0 auto;
    width: 1425px;
    max-width: 1425px;
    height: 381px;
    background-image: url("../assets/img/footer_bg.png");
    background-size: cover;
  }

  .footer .info .links {
    display: flex;
    justify-content: space-between;
    padding: 0 391px;
    padding-top: 87px;
  }

  .footer .info .links .link1 .title, .footer .info .links .link2 .title {
    font-size: 18px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 15px;
    letter-spacing: 1px;

    margin-bottom: 11px;
  }

  .footer .info .links .link1 a, .footer .info .links .link2 a, .footer .info .links .link1 .contact {
    font-size: 14px;
    font-family: ArialMT;
    color: #FFFFFF;
    line-height: 36px;

    display: block;
    text-decoration: none;
  }

  .footer .info .copyright {
    font-size: 15px;
    font-family: AlibabaPuHuiTiR;
    color: #FFFFFF;
    line-height: 15px;
    letter-spacing: 1px;

    text-align: center;
    margin-top: 80px;
  }
</style>
